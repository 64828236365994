import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isFavorite } from '../store/selectors';
import { IconButton } from '@material-ui/core';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { toggleFavorite } from '../store/actions';


const LikeButton = ({ racerId, toggleFavorite, isFavorite }) => {
    const FavIcon = isFavorite ? FavoriteIcon : FavoriteBorderIcon;

    return <IconButton edge="end" onClick={() => toggleFavorite(racerId)}>
            <FavIcon />
        </IconButton>;
};

export default connect((state, { racerId }) => {
    return {
        isFavorite: isFavorite(state, racerId),
    };
}, (dispatch) => ({
    toggleFavorite: (racerId) => dispatch(toggleFavorite(racerId)),
}))(LikeButton);
