import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import Layout from '../Layout';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import Ranking from '../../components/Ranking';
import Favorites from '../../components/Favorites';
import Paper from '@material-ui/core/Paper';


const useStyles = makeStyles(theme => ({
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(3, 2),
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        width: theme.spacing(7),
        height: theme.spacing(7),
        marginRight: theme.spacing(2),
    }
}));
const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];


const Home = function ({ push }) {
    const classes = useStyles();

    return (
        <Layout>
            {/* Hero unit */}
            <div className={classes.heroContent}>
                <Container maxWidth="sm">
                    <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                        Hel Van Kasterlee
                    </Typography>
                    <Typography variant="h5" align="left" color="textSecondary" paragraph>
                        Winterduathlon op 22 december 2019 in Kasterlee
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className={classes.avatar}>
                                    <DirectionsRunIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="15 km" secondary="Lopen" />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className={classes.avatar} >
                                    <DirectionsBikeIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="117 km" secondary="Mountainbike" />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className={classes.avatar}>
                                    <DirectionsRunIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="30 km" secondary="Lopen" />
                        </ListItem>
                    </List>


                    <div className={classes.heroButtons}>
                        <Grid container spacing={2} justify="center">
                            <Grid item>
                                <Button variant="contained" color="primary" onClick={e => push('/ranking')}>
                                    Ranking
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" color="primary" onClick={e => push('/racers')}>
                                    Racers
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </div>
            <Container className={classes.cardGrid} maxWidth="md">
                {/* End hero unit */}
                <Grid container spacing={4}>
                    <Grid item key="ranking" xs={12} sm={6}>
                        <Paper className={classes.paper}>
                            <Ranking delta="total" limit={5} />
                            <Button variant="contained" align="right" color="primary" onClick={e => push('/ranking')}>
                                Show more
                            </Button>
                        </Paper>
                    </Grid>
                    <Grid item key="dunno" xs={12} sm={6}>
                    <Paper className={classes.paper}>
                            <Favorites title="Favorites" limit={5} />
                            <Button variant="contained" align="right" color="primary" onClick={e => push('/favorites')}>
                                My Favorites
                            </Button>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    );
}

export default connect(null, { push })(Home);