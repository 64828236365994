import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware, { END } from 'redux-saga';
import sagaMonitor from '@redux-saga/simple-saga-monitor';
import createRootReducer from './reducers';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

export const history = createBrowserHistory();

const localStorageMiddleware = ({getState}) => {
  return (next) => (action) => {
      const result = next(action);
      const state = getState();
      localStorage.setItem('applicationState', JSON.stringify({
        racers: {
          favorites: state.racers.favorites,
        },
      }));
      return result;
  };
};

export default function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware({ sagaMonitor });
  const store = createStore(createRootReducer(history), initialState, applyMiddleware(routerMiddleware(history), sagaMiddleware, localStorageMiddleware));

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);
  return store;
}
