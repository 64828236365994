import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './containers/pages/Home';
import Ranking from './containers/pages/Ranking';
import Racers from './containers/pages/Racers';
import Racer from './containers/pages/Racer';

export default (
  <Switch>
    <Route path="/" exact component={Home} />
    <Route path="/ranking" exact component={Ranking} />
    <Route path="/racers" exact component={Racers} />
    <Route path="/racers/:id" component={Racer} />
  </Switch>
);
