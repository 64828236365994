import sortBy from 'lodash/sortBy';
import orderBy from 'lodash/orderBy';

export const getDeltas = (state) => state.deltas || [];
export const getCategories = (state) => state.categories;
export const getClubs = (state) => state.clubs;
export const getRacers = (state) => state.racers.list;
export const getRacer = (state, racer) => state.racers.list[racer];
export const getFavorites = (state) => state.racers.favorites || [];
export const isFavorite = (state, racerId) => getFavorites(state).indexOf(racerId) !== -1;
export const getFavoriteResults = (state) => {
    const results = getAllResults(state);

    return results.filter(({ racer }) => isFavorite(state, racer));
};
export const getCurrentRacer = (state, racer) => state.racers.current;
export const getClub = (state, club) => state.clubs[club];
export const getCategory = (state, category) => state.categories[category];
export const getDelta = (state, delta) => {
    const deltas = getDeltas(state);

    return deltas[delta] || undefined;
}
export const getResults = (state) => state.results || [];
export const getResultsByRacer = (state, racerToSelect) => {
    const results = getResults(state).filter(({ racer }) => racer === racerToSelect);

    return results && results[0];
};
export const getResultsByDelta = (state, delta = 'total') => {
    const results = getResults(state).reduce((results, racerResult) => {
        let result;
        if (delta === 'total') {
            result = { racer: racerResult.racer, result: racerResult.total };
        } else {
            result = { racer: racerResult.racer, result: racerResult.results[delta] };
        }

        if (result.result.position !== 0) {
            results.push(result);
        }

        return results;
    }, []);

    return sortBy(results, 'result.position');
};

export const getAllResults = (state) => {
    const results = getResults(state).map((racerResult) => {
        let totalTime = 0;
        let lastDelta = 0;
        let lastPosition = 0;

        for (let [delta, result] of Object.entries(racerResult.results)) {
            // console.log(delta, result);
            if (result.time === 0) break;

            totalTime += result.isTotal ? 0 : result.time;
            lastDelta = parseInt(delta);
            lastPosition = result.position;
        }

        return {
            racer: racerResult.racer,
            total: racerResult.total,
            result: {
                time: totalTime,
                lastDelta: lastDelta,
                lastPosition: lastPosition,
            }
        };
    }).filter(({ result }) => result.time !== 0);

    return orderBy(results, ['result.lastDelta', 'result.time'], ['desc', 'asc']);
};