function action(type, payload = {}) {
    return { type, ...payload }
}

export * from './racers';
export * from './categories';
export * from './clubs';
export * from './deltas';
export * from './results';

export const UPDATE_ROUTER_STATE = 'UPDATE_ROUTER_STATE';
export const NAVIGATE =  'NAVIGATE';
export const updateRouterState = state => action(UPDATE_ROUTER_STATE, {state});
export const navigate = pathname => action(NAVIGATE, {pathname});


export const LOAD_DATA = 'LOAD_DATA';
export const loadData = () => action(LOAD_DATA);

export const DATA_LOADED = 'DATA_LOADED';
export const dataLoaded = (data) => action(DATA_LOADED, {data});