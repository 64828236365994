import { take, fork, put, all, call, select } from 'redux-saga/effects';
import * as actions from '../actions';
import { getData } from '../../services/api';
import { push } from 'connected-react-router';
import keyBy from 'lodash/keyBy';
import groupBy from 'lodash/groupBy';
import uniq from 'lodash/uniq';
import invert from 'lodash/invert';
import { getDeltas, getCategories, getClubs, isFavorite } from '../selectors';

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

// https://github.com/supasate/connected-react-router/blob/master/FAQ.md#how-to-navigate-with-redux-action

function* loadData() {
  const data = yield call(getData);
  yield put(actions.dataLoaded(data));
}

function* watchLoadData() {
  while (true) {
    yield take(actions.LOAD_DATA);

    yield fork(loadData);
  }
}

function* watchInitialLoad() {
  yield take('@@router/LOCATION_CHANGE');

  yield put(actions.loadData());
}

function* watchDataLoaded() {
  while (true) {
    const { data } = yield take(actions.DATA_LOADED);
    yield fork(loadResults, data);
  }
}

function* watchSelectedRacer() {
  while (true) {
    const { racer } = yield take(actions.RACERS.SELECT);

    yield put(push(`/racers/${racer}`));
  }
}

function* watchFavoriteToggle() {
  while (true) {
    const { racer } = yield take(actions.RACERS.FAVORITE.TOGGLE);

    const isRacerFavorited = yield select(isFavorite, racer);

    if(isRacerFavorited) {
      yield put(actions.unsetFavorite(racer));
    } else {
      yield put(actions.setFavorite(racer));
    }
  }
}

function* loadRacers(data) {
  yield all([
    call(loadCategories, data),
    call(loadClubs, data),
  ]);

  const categories = yield select(getCategories);
  const clubs = yield select(getClubs);
  const categoryIdsByName = invert(categories);
  const clubIdsByName = invert(clubs);

  const racers = data.map(({ Nr, Name, License, Category, Club }) => ({
    id: parseInt(Nr),
    name: Name,
    license: License,
    category: categoryIdsByName[Category] || null,
    club: clubIdsByName[Club] || null,
  }));

  yield put(actions.setRacers(keyBy(racers, 'id')));
}

function* loadCategories(data) {
  const categories = uniq(data.map(({ Category }) => Category));

  yield put(actions.setCategories(categories));
}

function* loadClubs(data) {
  const clubs = uniq(data.map(({ Club }) => Club.trim()).filter(club => !!club)).sort();

  yield put(actions.setClubs(clubs));
}

function* loadDeltas(data) {
  const first = data[0] || {};

  const deltas = Object.keys(first).filter(prop => prop.indexOf('Time ') === 0).map(time => time.substr(5));

  yield put(actions.setDeltas(deltas));
}

function* loadResults(data) {
  yield all([
    fork(loadRacers, data),
    fork(loadDeltas, data),
  ]);

  yield call(loadRacers, data);

  const deltas = yield select(getDeltas);

  const results = data.map(({ Nr, Rang, Rank, TimeTotal, ...otherProps }) => (
    {
      racer: parseInt(Nr),
      results: keyBy(deltas.map((delta, deltaId) => ({
        delta: deltaId,
        position: parseInt(otherProps[delta]),
        time: parseInt(otherProps[`Time ${delta}`]),
        isTotal: ['Bike', 'Run2'].indexOf(delta) !== -1,
      })), 'delta'),
      total: {
        time: parseInt(TimeTotal),
        position: parseInt(Rang),
        groupPosition: parseInt(Rank),
      }
    }
  ));

  yield put(actions.setResults(results));
}

export default function* root() {
  yield all([
    fork(watchInitialLoad),
    fork(watchLoadData),
    fork(watchDataLoaded),
    fork(watchSelectedRacer),
    fork(watchFavoriteToggle),
  ]);
}
