import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import { IconButton, CircularProgress } from '@material-ui/core';
import SyncIcon from '@material-ui/icons/Sync';
import { connect } from 'react-redux';
import { loadData } from '../store/actions';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.contrastText,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  title: {
    flexGrow: 1,
  },
}));


const Layout = function ({ title = 'Hel Van Kasterlee', Icon = null, onIconClick, refreshData, children, loading = false }) {
  const classes = useStyles();

  Icon = Icon ? <Icon /> : <WhatshotIcon className={classes.icon} />;

  return (
    <>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          {
            onIconClick !== undefined ?
              <IconButton className={classes.icon} onClick={e => onIconClick(e)}>
                {Icon}
              </IconButton> :
              Icon
          }
          <Typography className={classes.title} variant="h6" color="inherit" noWrap>
            {title}
          </Typography>

          {loading ? <CircularProgress /> : <IconButton
            onClick={refreshData}
            color="inherit"
          ><SyncIcon /></IconButton>}

        </Toolbar>
      </AppBar>
      <main>
        {children}
      </main>
      {/* Footer */}
      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom>
          HVK
        </Typography>
        <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
          By vdwijngaert
        </Typography>
      </footer>
      {/* End footer */}
    </>
  );
}

export default connect((state) => ({
  loading: state.general.loading,
}), (dispatch) => ({
  refreshData: () => dispatch(loadData())
}))(Layout);