import React from 'react';
import { connect } from 'react-redux';
import { getDelta, getRacer, getFavoriteResults } from '../store/selectors';
import { Typography, List, ListItem, ListItemText, ListItemAvatar, Avatar, ListItemSecondaryAction, CircularProgress } from '@material-ui/core';
import { selectRacer } from '../store/actions';
import LikeButton from './LikeButton';
import { formatDuration } from '../util/duration';

const Favorites = ({ title = '', results, loading, getRacer, selectRacer, getDelta }) => {
    if (loading) {
        return <CircularProgress />;
    }

    if(!results.length) {
        return <Typography component="p">You don't have any favorites.</Typography>;
    }

    return <>
        <Typography component="h2" variant="h4" color="textPrimary" gutterBottom>
            {title}
        </Typography>
        <List>
            {
                results.map((result, index) => {
                    const { racer, result: { time, lastDelta, lastPosition }, total } = result;
                    const { name, id } = getRacer(racer);
                    const finished = !isNaN(total.position);

                    const rank = !finished ? 'DNF' : total.position;
                    const lastDeltaName = getDelta(lastDelta);
                    const secondaryText = finished ? formatDuration(time) : `${formatDuration(time)}, ${lastDeltaName} / Pos: ${lastPosition}`;
                    
                    return <ListItem key={index} onClick={e => selectRacer(parseInt(id))} button>
                        <ListItemAvatar>
                            <Avatar>{rank}</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={name}
                            secondary={secondaryText}
                        />
                        <ListItemSecondaryAction>
                            <LikeButton racerId={id} />
                        </ListItemSecondaryAction>
                    </ListItem>
                })
            }
        </List>
    </>
};

export default connect((state, { showTitle = true, limit }) => {
    const results = getFavoriteResults(state);

    return {
        results: limit ? results.slice(0, limit) : results,
        getRacer: (racer) => getRacer(state, racer),
        getDelta: (delta) => getDelta(state, delta),
        loading: state.general.loading,
    };
}, (dispatch) => ({
    selectRacer: (id) => dispatch(selectRacer(id)),
}))(Favorites);
