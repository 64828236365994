import { combineReducers } from 'redux';
import racers from './racers';
import clubs from './clubs';
import deltas from './deltas';
import categories from './categories';
import results from './results';
import { connectRouter } from 'connected-react-router';
import * as actions from '../actions';

const general = (state = {loading: false}, action) => {
    switch (action.type) {
      case actions.LOAD_DATA:
        return {...state, loading: true};
      case actions.DATA_LOADED:
        return {...state, loading: false};
      default:
        return state
    }
  };

const createRootReducer = (history) => combineReducers({
    general,
    racers,
    clubs,
    deltas,
    categories,
    results,
    router: connectRouter(history),
});

export default createRootReducer;