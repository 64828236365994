import * as actions from '../actions';

const defaultState = [];

const results = (state = defaultState, action) => {
    switch (action.type) {
      case actions.RESULTS.SET:
        return action.results;
      default:
        return state
    }
  };

  export default results;