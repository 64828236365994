import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import { ConnectedRouter } from 'connected-react-router';

// app specific imports
import routes from './routes';
import Root from './containers/Root';
import configureStore, { history } from './store/configureStore';
import rootSaga from './store/sagas';


const reHydrateStore = () => {
  if (localStorage.getItem('applicationState') !== null) {
      return JSON.parse(localStorage.getItem('applicationState')) 
  }
};

const store = configureStore(reHydrateStore());
store.runSaga(rootSaga);

ReactDOM.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <Root
      store={store}
      router={<ConnectedRouter history={history}>{routes}</ConnectedRouter>}
    />
  </ThemeProvider>,
  document.querySelector('#root'),
);
