import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Layout from '../Layout';
import { connect } from 'react-redux';
import { getRacer, getResultsByRacer, getCategory, getClub, getDeltas, getDelta } from '../../store/selectors';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { CircularProgress, Paper, ListItem, List, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import CategoryIcon from '@material-ui/icons/Category';
import { formatDuration } from '../../util/duration';
import TimerIcon from '@material-ui/icons/Timer';
import SpeedIcon from '@material-ui/icons/Speed';
import LikeButton from '../../components/LikeButton';

const useStyles = makeStyles(theme => ({
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    paper: {
        padding: theme.spacing(3, 2),
        marginBottom: theme.spacing(2),
    },
    finishedResult: {
        backgroundColor: theme.palette.primary.main,
    }
}));

const RacerInfo = connect((state, { racerId }) => {
    const racer = getRacer(state, racerId);

    return {
        racer,
        category: racer ? getCategory(state, racer.category) : null,
        club: racer ? getClub(state, racer.club) : null,
    }
})(({ racer, club, category }) => {
    const classes = useStyles();

    return <Paper className={classes.paper}>
        <Typography component="h2" variant="h5" color="textPrimary" gutterBottom>
            Information
        </Typography>
        <List className={classes.root}>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <PersonIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={racer.name} secondary="Name" />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <CategoryIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={category} secondary="Category" />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <GroupIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={club || "n/a"} secondary="Club" />
            </ListItem>
        </List>
    </Paper>;
});

const RacerResult = ({ result, delta }) => {
    const classes = useStyles();

    const secondary = result.isTotal ? `${delta} (TOTAL)` : delta;

    const icon = result.isTotal ? <SpeedIcon /> : <TimerIcon />;

    return <ListItem>
        <ListItemAvatar>
            <Avatar className={result.time !== 0 ? classes.finishedResult : ''}>
                {icon}
            </Avatar>
        </ListItemAvatar>
        <ListItemText primary={formatDuration(result.time)} secondary={secondary} />
    </ListItem>
};

const RacerResults = connect((state, { racerId }) => {
    const racer = getRacer(state, racerId);

    return {
        racer,
        results: getResultsByRacer(state, racerId),
        getDelta: (deltaId) => getDelta(state, deltaId),
        deltas: getDeltas(state),
    }
})(({ racer, results, getDelta, deltas }) => {
    const classes = useStyles();


    return <Paper className={classes.paper}>
        <Typography component="h2" variant="h5" color="textPrimary" gutterBottom>
            Results
        </Typography>
        <List className={classes.root}>
            {
                results ?
                    Object.keys(results.results).map((index) => {
                        const raceResult = results.results[index];
                        return <RacerResult key={index} delta={getDelta(raceResult.delta)} result={raceResult} />
                    }
                    ) : <CircularProgress />
            }
        </List>
    </Paper>;
});

const Racer = function ({ racer, results, category, club }) {
    const classes = useStyles();

    return (
        <Layout title="HVK: Racer" Icon={ArrowBackIcon} onIconClick={e => window.history.go(-1)}>
            {/* Hero unit */}
            <div className={classes.heroContent}>
                <Container maxWidth="sm">
                    {racer ? <>
                        <Typography component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
                            {racer.name} 
                            <LikeButton racerId={racer.id} />
                        </Typography>
                        <RacerInfo racerId={racer.id} />
                        <RacerResults racerId={racer.id} />
                    </>
                        :
                        <CircularProgress />
                    }
                </Container>
            </div>
        </Layout>
    );
}

export default connect((state, { match: { params: { id } } }) => {
    const racer = getRacer(state, parseInt(id));
    return {
        racer,
    }
})(Racer);