import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getRacer, getRacers } from '../store/selectors';
import { List, ListItem, ListItemText, ListItemAvatar, Avatar, ListItemSecondaryAction, IconButton, CircularProgress } from '@material-ui/core';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { selectRacer } from '../store/actions';

const Racers = ({ racers, getRacer, selectRacer }) => {
    if (!racers) {
        return <CircularProgress />;
    }

    const keys = Object.keys(racers);

    if (!keys.length) {
        return <CircularProgress />;
    }

    return <>
        <List>
            {
                Object.keys(racers).map((id) => {
                    const racer = racers[id];
                    const { name } = racer;

                    return <ListItem key={id} onClick={e => selectRacer(parseInt(id))} button>
                        <ListItemAvatar>
                            <Avatar>{id}</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={name}
                        />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete">
                                <FavoriteBorderIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                })
            }
        </List>
    </>
};

Racers.propTypes = {
    racers: PropTypes.object.isRequired,
    getRacer: PropTypes.func.isRequired,
    selectRacer: PropTypes.func.isRequired,
};

export default connect((state) => ({
    racers: getRacers(state),
    getRacer: (racer) => getRacer(state, racer),
}), (dispatch) => ({
    selectRacer: (id) => dispatch(selectRacer(id)),
}))(Racers);
