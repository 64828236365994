import * as actions from '../actions';
import { combineReducers } from 'redux';

const defaultState = {};

export const list = (state = defaultState, action) => {
  switch (action.type) {
    case actions.RACERS.SET:
      return action.racers;
    default:
      return state
  }
};

export const current = (state = -1, action) => {
  switch (action.type) {
    case actions.RACERS.SELECT:
      return action.racer;
    default:
      return state
  }
};
export const favorites = (state = [], action) => {
  switch (action.type) {
    case actions.RACERS.FAVORITE.SET:
      return [...state, action.racer];
    case actions.RACERS.FAVORITE.UNSET:
      return state.filter(favorite => favorite !== action.racer);
    default:
      return state
  }
};

export default combineReducers({
  list,
  current,
  favorites,
});