import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
// import DevTools from './DevTools'

export default class Root extends Component {
  render() {
    const { store, router } = this.props;

    return (
      <Provider store={store}>
        {router}
      </Provider>
    );
  }
};

Root.propTypes = {
  store: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};
