import * as actions from '../actions';

const defaultState = [];

const deltas = (state = defaultState, action) => {
    switch (action.type) {
      case actions.DELTAS.SET:
        return action.deltas;
      default:
        return state
    }
  };

  export default deltas;