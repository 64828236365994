function action(type, payload = {}) {
    return { type, ...payload }
}

export const RACERS = {
    SET: 'SET_RACERS',
    SELECT: 'SELECT_RACER',
    FAVORITE: {
        TOGGLE: 'FAVORITE_TOGGLE',
        SET: 'FAVORITE_SET',
        UNSET: 'FAVORITE_UNSET',
    }
};

export const setRacers = racers => ({
    type: RACERS.SET,
    racers
});

export const selectRacer = racer => ({
    type: RACERS.SELECT,
    racer
});

export const toggleFavorite = (racer) => action(RACERS.FAVORITE.TOGGLE, {racer});
export const setFavorite = (racer) => action(RACERS.FAVORITE.SET, {racer});
export const unsetFavorite = (racer) => action(RACERS.FAVORITE.UNSET, {racer});