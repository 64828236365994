import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getDelta, getRacer, getResultsByDelta, getAllResults } from '../store/selectors';
import { Typography, List, ListItem, ListItemText, ListItemAvatar, Avatar, ListItemSecondaryAction, IconButton, CircularProgress } from '@material-ui/core';
import * as moment from 'moment';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { selectRacer } from '../store/actions';
import LikeButton from './LikeButton';

const formatDuration = function (milliseconds) {
    const duration = moment.duration(milliseconds, 'milliseconds');

    return [duration.hours(), duration.minutes(), duration.seconds()].map((current) => {
        return (current < 10) ? '0' + current : '' + current;
    }, '').join(':');
}

const Ranking = ({ title, results, getRacer, delta, selectRacer, getDelta }) => {
    if (!results.length) {
        return <CircularProgress />;
    }

    return <>
        <Typography component="h2" variant="h4" color="textPrimary" gutterBottom>
            {title}
        </Typography>
        <List>
            {
                results.map((result, index) => {
                    const { racer, result: { time, lastDelta, lastPosition }, total } = result;
                    const { name, id } = getRacer(racer);
                    const finished = !isNaN(total.position);

                    const rank = !finished ? 'DNF' : total.position;
                    const lastDeltaName = getDelta(lastDelta);
                    const secondaryText = finished ? formatDuration(time) : `${formatDuration(time)}, ${lastDeltaName} / Pos: ${lastPosition}`;
                    
                    return <ListItem key={index} onClick={e => selectRacer(parseInt(id))} button>
                        <ListItemAvatar>
                            <Avatar>{delta === 'total' ? rank : (index + 1)}</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={name}
                            secondary={secondaryText}
                        />
                        <ListItemSecondaryAction>
                            <LikeButton racerId={id} />
                        </ListItemSecondaryAction>
                    </ListItem>
                })
            }
        </List>
    </>
};

Ranking.propTypes = {
    title: PropTypes.string.isRequired,
    results: PropTypes.array.isRequired,
    getRacer: PropTypes.func.isRequired,
    delta: PropTypes.any,
};

export default connect((state, { showTitle = true, delta, limit }) => {
    const allResults = delta === 'total' ? getAllResults(state) : getResultsByDelta(state, delta);

    return {
        title: showTitle ? (delta === 'total' ? 'Ranking' : getDelta(state, delta)) : '',
        results: limit ? allResults.slice(0, limit) : allResults,
        getRacer: (racer) => getRacer(state, racer),
        getDelta: (delta) => getDelta(state, delta),
    };
}, (dispatch) => ({
    selectRacer: (id) => dispatch(selectRacer(id)),
}))(Ranking);
