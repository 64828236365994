import * as actions from '../actions';

const defaultState = [];

const clubs = (state = defaultState, action) => {
    switch (action.type) {
      case actions.CLUBS.SET:
        return action.clubs;
      default:
        return state
    }
  };

  export default clubs;