import * as actions from '../actions';

const defaultState = [];

const categories = (state = defaultState, action) => {
    switch (action.type) {
      case actions.CATEGORIES.SET:
        return action.categories;
      default:
        return state
    }
  };

  export default categories;