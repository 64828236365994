import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Layout from '../Layout';
import RacersComponent from '../../components/Racers';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Paper from '@material-ui/core/Paper';


const useStyles = makeStyles(theme => ({
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    paper: {
        padding: theme.spacing(3, 2),
    },
}));

export default function Racers() {
    const classes = useStyles();

    return (
        <Layout title="HVK: Racers" Icon={ArrowBackIcon} onIconClick={e => window.history.go(-1)}>
            {/* Hero unit */}
            <div className={classes.heroContent}>
                <Container maxWidth="sm">
                    <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                        Racers
                    </Typography>
                    <Paper className={classes.paper}>
                        <RacersComponent />
                    </Paper>
                </Container>
            </div>
        </Layout>
    );
}